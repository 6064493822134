import React, { useState } from "react";
import { Modal, Form, Button, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import useFetch from "../../../hooks/useFetch";

const addNewService = yup.object().shape({
  code: yup.string().required("El código de la tarea es requerido"),
  description: yup.string().required("La descripción de la tarea es requerida"),
  price: yup.string().required("El precio de la tarea es requerido"),
});

const ModalAddService = ({ open, handleClose, id, update }) => {
  const { fletesAPI } = useFetch();
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(addNewService),
    defaultValues: {
      code: "",
      description: "",
      price: "",
    },
  });

  const onClose = () => {
    handleClose();
    reset();
  };

  const addService = async (data) => {
    setLoading(true);
    try {
      await fletesAPI.post(`api/freight/${id}/services/`, data);
      onClose();
      update();
    } catch (e) {
      throw new Error(e);
    }
    finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    await toast.promise(addService(data), {
      loading: "Guardando servicio...",
      success: "Servicio guardado correctamente",
      error: "Error al guardar el servicio",
    });
  };

  return (
    <Modal show={open} onHide={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Servicio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Código</Form.Label>
            <Form.Control type="text" placeholder="Código del servicio" {...register("code")} />
            <Form.Text className="text-danger">{errors.code?.message}</Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              type="text"
              placeholder="Descripción del servicio"
              {...register("description")}
            />
            <Form.Text className="text-danger">{errors.description?.message}</Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Precio</Form.Label>
            <Form.Control type="text" placeholder="Precio" {...register("price")} />
            <Form.Text className="text-danger">{errors.price?.message}</Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type="submit" disabled={loading}>
            {loading ? "Guardando..." : "Agregar"}
          </Button>
          <Button variant="danger" onClick={onClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalAddService;
