import { useState, useRef, useContext } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import AvatarEditor from "react-avatar-editor";
import styled from "styled-components";
import toast from "react-hot-toast";
import { MdClose } from "react-icons/md";
import { mensajesAPI } from "../../../utils/mensajes";
import useFetch from "../../../hooks/useFetch";
import { BodegasContext } from "../../../context";
import RenderDropPictureProfile from "../../../fyb_components/results/RenderDropPictureProfile";

const AvatarEdit = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  input {
    width: 100%;
  }
  .groupRanges {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  .avatarCont {
    position: relative;
    width: 350px;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      position: absolute;
      top: 10px;
      left: 10px;
      cursor: pointer;
      fill: #fff;
      z-index: 1;
      width: 20px;
      height: 20px;
    }
  }
`;

const ModalTopLogoCompany = ({ state, setState }) => {
  const [zoom, setZoom] = useState(1);
  const [image, setImage] = useState(null);
  const [rotate, setRotate] = useState(0);
  const editor = useRef(null);
  const { fletesAPI } = useFetch();
  const { companyId } = useContext(BodegasContext);

  const resetAll = () => {
    setZoom(1);
    setImage(null);
    setRotate(0);
  };

  const getImageBase64 = () => {
    return editor.current.getImageScaledToCanvas().toDataURL().split(",")[1];
  };

  const uploadProfilePicture = async () => {
    try {
      if (image) {
        const base64Image = getImageBase64();
        const payload = {
          key: "logo",
          value: base64Image,
        };

        await fletesAPI
          .patch(`api/company/${companyId}/database/`, payload)
          .then(() => {
            setState(false);
            resetAll();
          })
          .catch((e) => {
            console.error(e);
          });
      } else {
        throw new Error("Debes seleccionar una imagen");
      }
    } catch (error) {
      throw new Error(mensajesAPI(error));
    }
  };

  return (
    <>
      <Modal
        show={state}
        onHide={() => {
          setState(false);
          resetAll();
        }}
        className="modal-dialog-centered"
      >
        <Modal.Header closeButton />
        <Modal.Body className="overflow-auto">
          <AvatarEdit>
            <h5>Actualizar logo de Compañia</h5>
            {image ? (
              <div className="avatarCont">
                <MdClose onClick={() => setImage(null)} />
                <AvatarEditor
                  image={image}
                  width={310}
                  height={100}
                  border={20}
                  color={[0, 0, 0, 0.6]}
                  scale={zoom}
                  rotate={rotate}
                  borderRadius={0}
                  style={{ borderRadius: "10px" }}
                  ref={editor}
                />
              </div>
            ) : (
              <RenderDropPictureProfile setImage={setImage} />
            )}
            <div className="groupRanges">
              <Form.Group>
                <Form.Label>Zoom:</Form.Label>
                <input
                  type="range"
                  min="1"
                  max="2"
                  step="0.01"
                  value={zoom}
                  onChange={(e) => setZoom(parseFloat(e.target.value))}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Rotar:</Form.Label>
                <input
                  type="range"
                  min="0"
                  max="360"
                  step="1"
                  value={rotate}
                  onChange={(e) => setRotate(parseInt(e.target.value, 10))}
                />
              </Form.Group>
            </div>
          </AvatarEdit>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => {
            toast.promise(uploadProfilePicture(), {
              loading: "Guardando...",
              success: "Logo actualizado",
              error: "Seleccione una imagen", 
            });
          }}>
            Actualizar
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setState(!state);
              resetAll();
            }}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalTopLogoCompany;
