import { useRef, useState, useEffect } from "react";
import { Button, Container, Row, Card, ListGroup } from "react-bootstrap";
import moment from "moment";
import { fullNameCity, getHtmlBoardingRequest } from "../../utils";
import { formatPhoneNumber } from "react-phone-number-input";
import logoInit from "../../assets/icons/logo.svg";
import useFetch from "../../hooks/useFetch";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import toast from "react-hot-toast";

const FinishBoardingRequest = ({ task, taskId }) => {
  const [logo, setLogo] = useState(logoInit);
  const [services, setServices] = useState([]);
  const { order, invoice } = task;
  const { fletesAPI, fletesAPIFile } = useFetch();
  const { quote } = order;
  const html = getHtmlBoardingRequest({ order, invoice, url_logo: `data:image/png;base64,${logo}`, services });
  console.log({ services });
  

  const getLogo = async () => {
    try {
      const response = await fletesAPI.get(`/api/company/${order?.quote?.company?.id}/database/`, {
        params: {
          key: "logo",
        }
      });
      setLogo(response.data.value);
    } catch (e) {
      console.log(e);
    }
  };

  const getServices = async () => {
    try {
      const response = await fletesAPI.get(`api/freight/${order.id}/services/`);
      setServices(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const cardRef = useRef(null);

  const addComment = async (comment) => {
    try {
      fletesAPI.post(`api/freight/${order.id}/comment/`, { comment });
    } catch (error) {
      throw Error(error);
    }
  };

  const completeTask = async () => {
    try {
      await fletesAPI.patch(`api/freight/${order.id}/task/complete/`, {
        status: 3,
        comment_submitter: "",
        task_id: taskId,
      });
      window.location.replace(`/dashboard/my_order/${order.id}/tasks`);
    } catch (error) {
      throw Error(error);
    }
  };

  const handleDownload = async () => {
    try {
      let iframe = document.createElement('iframe');
      iframe.style.position = "absolute";
      iframe.style.top = "-9999px";
      document.body.appendChild(iframe);
      const docBR = iframe.contentDocument || iframe.contentWindow.document;
      docBR.open();
      docBR.write(html);
      docBR.close();
      await new Promise((resolve) => {
        iframe.onload = resolve;
      });
      const canvas = await html2canvas(docBR.body, { scale: 2, useCORS: true });
      const imgData = canvas.toDataURL("image/jpeg");
      const pdf = new jsPDF("p", "pt", "a4");
      pdf.addImage(
        imgData,
        "JPEG",
        0,
        0,
        pdf.internal.pageSize.getWidth(),
        pdf.internal.pageSize.getHeight()
      );
      // ===============================================
      const pdfBlob = pdf.output("blob");
      let formData = new FormData();
      formData.append("file_name", `Carta_instrucciones_${order?.reference || "Sin_nombre"}.pdf`);
      formData.append("type", "application/pdf");
      formData.append("file_content", pdfBlob);
      const promise = await fletesAPIFile.post(`api/freight/${order.id}/attachment/`, formData);
      console.log(promise);
      const newComment = `Se ha adjuntado una carta de instrucciones: ${promise.data.url}`;
      await addComment(newComment);
      pdf.save(`Carta de instrucciones - ${order?.reference || "Sin nombre"}.pdf`);
      completeTask();
      document.body.removeChild(iframe);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getLogo();
    getServices();
  }, []);

  return (
    <Container>
      <Row className="justify-content-center">
        <div className="d-flex flex-column justify-content-center align-items-center gap-4">
          <div className="w-100 d-flex justify-content-end" style={{ maxWidth: "8.5in" }}>
            <Button
              onClick={() => {
                toast.promise(handleDownload(), {
                  loading: "Generando PDF...",
                  success: "PDF generado con éxito",
                  error: "Error al generar PDF",
                });
              }}
              className="text-white"
            >
              Descargar PDF y Finalizar tarea
            </Button>
          </div>
          <Card ref={cardRef} style={{ width: "8.5in", minHeight: "11in" }}>
            <Card.Body className="gap-2 d-flex flex-column">
              <div
                className="d-flex justify-content-between align-items-center mb-4 p-2"
                style={{ borderRadius: 5 }}
              >
                <img src={`data:image/png;base64,${logo}`} alt="logo" style={{ width: "150px", height: "auto" }} />
                <div className="text-right d-flex flex-column justify-content-end align-items-end">
                  <p className="m-0 fw-bolder">Carta de instrucciones No:</p>
                  <p className="m-0">{order.reference || "Nombre de la Compañía"}</p>
                </div>
              </div>
              <ListGroup>
                <ListGroup.Item style={{ background: "#0f2840", color: "white" }}>
                  <div className="header-card_section flex justify-content-center">
                    <p>
                      <b>Transportista</b>
                    </p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
                    <p>
                      <b>Compañía:</b> {quote?.company?.name || ""}
                    </p>
                    <p>
                      <b>Nombre:</b> {quote?.company?.contact_information?.name || ""}
                    </p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
                    <p className="m-0">
                      <b>Correo:</b> {quote?.company?.contact_information?.email || ""}
                    </p>
                    <p className="m-0">
                      <b>Teléfono:</b>{" "}
                      {formatPhoneNumber(quote?.company?.contact_information?.phone) || ""}
                    </p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
                    <p className="m-0">
                      <b>Dirección:</b> {quote?.company?.contact_information?.address_line1 || ""}
                    </p>
                    <p className="m-0">
                      <b>Código postal:</b> {quote?.company?.contact_information?.zip_code || ""}
                    </p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
                    <p className="m-0">
                      <b>Camión:</b> {quote?.bed_type?.name || ""}
                    </p>
                    <p className="m-0">
                      <b>Placas:</b> {quote?.truck?.license_plate || "N/A"}
                    </p>
                  </div>
                </ListGroup.Item>
              </ListGroup>
              <div className="d-flex ">
                <ListGroup className="w-50">
                  <ListGroup.Item style={{ background: "#0f2840", color: "white" }}>
                    <div className="header-card_section flex justify-content-center">
                      <p>
                        <b>Origen</b>
                      </p>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Origen:</b> {fullNameCity(quote?.origin_city) || ""}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Dirección:</b>{" "}
                    {`${order?.origin_address?.address_line1} | ${order?.origin_address?.address_line2} | ${order?.origin_address?.zip_code}` ||
                      ""}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Fecha:</b> {moment.utc(quote?.pickup_time).format("YYYY-MM-DD HH:mm") || ""}
                  </ListGroup.Item>
                </ListGroup>
                <ListGroup className="w-50">
                  <ListGroup.Item style={{ background: "#0f2840", color: "white" }}>
                    <div className="header-card_section flex justify-content-center">
                      <p>
                        <b>Destino</b>
                      </p>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Destino:</b> {fullNameCity(quote?.destination_city) || ""}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Dirección:</b>{" "}
                    {`${order?.destination_address?.address_line1} | ${order?.destination_address?.address_line2} | ${order?.destination_address?.zip_code}` ||
                      ""}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Fecha:</b>{" "}
                    {moment.utc(quote?.delivery_time).format("YYYY-MM-DD HH:mm") || ""}
                  </ListGroup.Item>
                </ListGroup>
              </div>
              <ListGroup>
                <ListGroup.Item style={{ background: "#0f2840", color: "white" }}>
                  <div className="header-card_section flex justify-content-center">
                    <p>
                      <b>Detalles del Flete</b>
                    </p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-between w-100">
                    <p className="m-0">
                      <b>Tarimas:</b> {quote?.pallets || ""}
                    </p>
                    <p className="m-0">
                      <b>Peso:</b> {quote?.weight || ""}
                      {" kg"}
                    </p>
                    <p className="m-0">
                      <b>Carga:</b> {quote?.cargo || ""}
                    </p>
                  </div>
                </ListGroup.Item>
              </ListGroup>
              <ListGroup>
                <ListGroup.Item style={{ background: "#0f2840", color: "white" }}>
                  <div className="header-card_section flex justify-content-center">
                    <p>
                      <b>Información de Factura</b>
                    </p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
                    <p className="m-0">
                      <b>Nombre legal:</b> {invoice?.legal_name || ""}
                    </p>
                    <p className="m-0">
                      <b>RFC:</b> {invoice?.rfc_code || ""}
                    </p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
                    <p className="m-0">
                      <b>Correo:</b> {invoice?.address?.email || ""}
                    </p>
                    <p className="m-0">
                      <b>Teléfono:</b> {invoice?.address?.phone || ""}
                    </p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
                    <p className="m-0">
                      <b>Dirección:</b>{" "}
                      {invoice
                        ? `${invoice?.address?.address_line1} ${invoice?.address?.address_line2}`
                        : ""}
                    </p>
                    <p className="m-0">
                      <b>Código Postal:</b> {invoice?.address?.zip_code || ""}
                    </p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Ciudad:</b> {fullNameCity(invoice?.address?.city) || ""}
                </ListGroup.Item>
              </ListGroup>
              <ListGroup>
                <ListGroup.Item style={{ background: "#0f2840", color: "white" }}>
                  <div className="header-card_section flex justify-content-center">
                    <p>
                      <b>Desglosado de servicios</b>
                    </p>
                  </div>
                </ListGroup.Item>
                {services.map((service, index) => (
                <ListGroup.Item key={index}>
                  <div style={{ display: "grid", gridTemplateColumns: "0.5fr 3fr 1.5fr", gap: "10px" }}>
                  <p className="m-0">
                      <b>{service?.code || ""}</b> 
                    </p>
                    <p className="m-0">
                      <b>Descripción:</b> {service?.description || ""}
                    </p>
                    <div className="m-0" style={{ display: "flex", justifyContent: "space-between" }}>
                      <p><b>Precio:</b></p> <p>${service?.price || ""} {quote?.currency|| ""}</p>
                    </div>
                  </div>
                </ListGroup.Item>
                ))}
                <ListGroup.Item>
                  <div style={{ display: "grid", gridTemplateColumns: "3.5fr 1.5fr", gap: "10px" }}>
                    <p className="m-0">
                    </p>
                    <div className="m-0" style={{ display: "flex", justifyContent: "space-between" }}>
                      <p><b>Total:</b></p> <p>${quote?.total || ""} {quote?.currency|| ""}</p>
                    </div>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </div>
      </Row>
    </Container>
  );
};

export default FinishBoardingRequest;
