import React from "react";
import toast from "react-hot-toast";
import { Modal, Button } from "react-bootstrap";
import useFetch from "../../../hooks/useFetch.js";

const ModalDelService = ({ show, hideModal, id, service, update }) => {
  const { fletesAPI } = useFetch();

  const deleteService = () => {

    const promise = fletesAPI.delete(`api/freight/${id}/services/`, {
      data: { service_line_id: service.id },
    })

    toast.promise(promise, {
      loading: "Eliminando servicio...",
      success: "Servicio eliminado correctamente",
      error: "Error al eliminar el servicio",
    });

    promise.then(() => {
      hideModal();
      update();
    })
    .catch((e) => {
      console.log(e);
    });
  };

  return (
    <Modal show={show} onHide={hideModal}>
      <Modal.Header closeButton>
        <h5 className="fyb_h5 text-center">Borrar servicio</h5>
      </Modal.Header>
      {service && (
        <>
          <Modal.Body>
            <h6>¿Estás seguro de eliminar este servicio?</h6>
            <p>
              {service.code} | {service.description}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success"  size="sm" onClick={() => deleteService()} >
              Eliminar
            </Button>
            <Button variant="danger" size="sm" onClick={hideModal}>
              Cancelar
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default ModalDelService;
