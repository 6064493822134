import { useEffect, useState } from "react";
import { Button, Row, Col, Spinner, Table } from "react-bootstrap";
import RenderSpace from "../../../../fyb_components/results/RenderSpace";
import useFetch from "../../../../hooks/useFetch";
import ModalAddService from "../ModalAddNewService";
import { MdDelete } from "react-icons/md";
import ModalDelService from "../ModalDelService";

const Services = ({ order }) => {
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalDel, setModalDel] = useState(false);
  const [selected, setSelected] = useState(null);
  const { fletesAPI } = useFetch();

  const getServices = async () => {
    setLoading(true);
    try {
      const response = await fletesAPI.get(`api/freight/${order.id}/services/`);
      setServices(response.data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const deleteService = async (service) => {
    setSelected(service);
    setModalDel(true);
  };

  useEffect(() => {
    getServices();
  }, []);

  return (
    <>
      <Row>
        <Col className="d-flex justify-content-end align-items-center">
          <Button variant="primary" className="text-white" onClick={() => setModalAdd(true)}>
            Agregar Servicio
          </Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          {loading ? (
            <RenderSpace jc="center">
              <Spinner animation="border" role="status" />
            </RenderSpace>
          ) : (
            <>
              {services && services.length > 0 ? (
                <Table striped responsive className="dashboard_main_container_table">
                  <thead>
                    <tr>
                      <th>Codigo</th>
                      <th>Description</th>
                      <th>Total</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {services.map((service) => (
                      <tr key={service.id}>
                        <td>{service.code}</td>
                        <td>{service.description}</td>
                        <td>{service.price} {order?.quote?.currency}</td>
                        <td>
                          <Button className="bnt btn-danger" size="sm" onClick={() => deleteService(service)}>
                            <MdDelete color="white" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <RenderSpace>
                  <p>No hay archivos</p>
                </RenderSpace>
              )}
            </>
          )}
        </Col>
      </Row>
      <ModalAddService
        id={order.id}
        open={modalAdd}
        handleClose={() => setModalAdd(false)}
        update={getServices}
      />
      <ModalDelService
        show={modalDel}
        hideModal={() => setModalDel(false)}
        id={order.id}
        service={selected}
        update={getServices}
      />
    </>
  );
};

export default Services;
